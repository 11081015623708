import React from 'react';
import NovaPoshtaSelector from './NovaPoshtaSelector';
import PhoneInput from 'react-phone-input-2';

function Details({ data, updateField, errors }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        updateField(name, value);
    };

    const updateCity = (selectedCity) => {
        updateField('city', selectedCity.label);
    };

    const updateWarehouse = (selectedWarehouse) => {
        updateField('address', selectedWarehouse.label);
    };

    const handleDeliveryChange = (event) => {
        updateField('deliveryType', event.target.value);
    };

    const handleAddressChange = (event) => {
        updateField('address', event.target.value);
    };

    const handlePhoneChange = (phone) => {
        updateField('phone', phone);
    };

    return (
        <div className='shipping-content'>
            <div className='space-between'>
                <h1>АДРЕСА ВІДПРАВЛЕННЯ</h1>
            </div>
            <div>
                <div className="line-divider"></div>
                <div className="divider">
                    <label className={errors.name ? 'error' : ''}>
                        Імʼя
                        <input placeholder='Дмитро' name='name' value={data.name} onChange={handleChange} />
                        {/* {errors.name && <span className="error-message">{errors.name}</span>} */}
                    </label>
                    <label className={errors.surname ? 'error' : ''}>
                        Прізвище
                        <input placeholder='Заглада' name='surname' value={data.surname} onChange={handleChange} />
                        {/* {errors.surname && <span className="error-message">{errors.surname}</span>} */}
                    </label>
                </div>
                <div className="divider">
                    <label className={errors.phone ? 'error' : ''}>
                        Телефон
                        <PhoneInput
                            country={'ua'}
                            onlyCountries={['ua']}
                            masks={{ ua: '(..) ...-..-..' }}
                            placeholder='+38 (___) ___-__-__'
                            value={data.phone}
                            onChange={handlePhoneChange}
                        />
                        {/* {errors.phone && <span className="error-message">{errors.phone}</span>} */}
                    </label>
                    <label className={errors.email ? 'error' : ''}>
                        Email
                        <input placeholder='your@email.com' name='email' value={data.email} onChange={handleChange} />
                        {/* {errors.email && <span className="error-message">{errors.email}</span>} */}
                    </label>
                </div>
                <div className="divider">
                    <label className={errors.deliveryType ? 'error' : ''}>
                        <select name="deliveryType" value={data.deliveryType} onChange={handleDeliveryChange}>
                            <option value="" disabled>Оберіть спосіб доставки</option>
                            <option value={'Нова Пошта'}>Нова Пошта</option>
                            <option value={'Самовивіз'}>Самовивіз</option>
                        </select>
                        {/* {errors.deliveryType && <span className="error-message">{errors.deliveryType}</span>} */}
                    </label>
                    {data.deliveryType === 'Самовивіз' && (
                        <label className={errors.address ? 'error' : ''}>
                            <select name="address" value={data.address} onChange={handleAddressChange}>
                                <option value="" disabled>Оберіть адресу</option>
                                <option value={'м. Київ, вул. Доброхотова 20/19'}>м. Київ, вул. Доброхотова 20/19</option>
                            </select>
                        </label>
                    )}
                </div>
                {data.deliveryType === 'Нова Пошта' && (
                    <NovaPoshtaSelector updateOrderCity={updateCity} updateWarehouse={updateWarehouse} />
                )}
            </div>
        </div>
    );
}

export default Details;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from "../../assets/icons/facebook.svg";
import InstagramIcon from "../../assets/icons/instagram.svg";
import TelegramIcon from "../../assets/icons/telegram.svg";
import { Modal } from '../modal/Modal';
import AboutUs from '../../pages/AboutUs';
import ReturnsAndExchanges from '../../pages/ReturnsAndExchanges';
import DeliveryAndPayment from '../../pages/DeliveryAndPayment';

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  // Функція для відкриття модального вікна з "Наша історія"
  const handleOpenAboutUs = () => {
    setCurrentPage('about');
    setModalIsOpen(true);
  };

  // Функція для відкриття модального вікна з "Обмін/Повернення"
  const handleOpenReturnsAndExchanges = () => {
    setCurrentPage('returns');
    setModalIsOpen(true);
  };

  // Функція для відкриття модального вікна з "Оплата і доставка"
  const handleOpenDeliveryAndPayment = () => {
    setCurrentPage('delivery');
    setModalIsOpen(true);
  };

  // Логіка для блокування скролу при відкритті модалки
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalIsOpen]);

  let modalContent;
  if (currentPage === 'about') {
    modalContent = <AboutUs onClose={() => setModalIsOpen(false)} />;
  } else if (currentPage === 'returns') {
    modalContent = <ReturnsAndExchanges onClose={() => setModalIsOpen(false)} />;
  } else if (currentPage === 'delivery') {
    modalContent = <DeliveryAndPayment onClose={() => setModalIsOpen(false)} />; // Додаємо модальне вікно для "Оплата і доставка"
  }

  return (
    <div className="footer">
      <div className='footer-section'>
        <h3>ПРО НАС</h3>
        <ul>
          <li>
            <Link onClick={handleOpenAboutUs}>Наша історія</Link>
          </li>
        </ul>
      </div>
      <div className='footer-section'>
        <h3>СЛУЖБА ПІДТРИМКИ</h3>
        <ul>
          <li>
            <Link onClick={handleOpenDeliveryAndPayment}>Оплата і доставка</Link>
          </li>
          <li>
            <Link onClick={handleOpenReturnsAndExchanges}>Обмін/Повернення</Link>
          </li>
        </ul>
      </div>
      <div className='footer-socials'>
        <div className='social-icon-container'>
          <Link to="https://www.facebook.com/profile.php?id=61566370964250">
            <img src={FacebookIcon} alt="Facebook" />
          </Link>
        </div>
        <div className='social-icon-container'>
          <Link to="https://www.instagram.com/minimax.biz?igsh=YjM1NGpnMWd2dGZ0">
            <img src={InstagramIcon} alt="Instagram" />
          </Link>
        </div>
        <div className='social-icon-container'>
          <Link to="https://t.me/+380954505545">
            <img src={TelegramIcon} alt="Telegram" />
          </Link>
        </div>
      </div>

      {/* Модальне вікно */}
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import orderApi from '../../utils/api/orderApi';
import SyncLoader from "react-spinners/SyncLoader";
import { ReactComponent as IconSuccses } from '../../assets/icons/circle-check-regular.svg';
import { ReactComponent as IconError } from '../../assets/icons/circle-xmark-regular.svg';

function Complete({ onClose, order, setActiveTab }) {
  const [isLoading, setIsLoading] = useState(true);
  const [orderCreated, setOrderCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    console.log("EFFECT");
    let isMounted = true;

    const createOrder = async () => {
      try {
        console.log({ order });
        const response = await orderApi.createOrder(order);
        console.log("CREATEORDER");

        if (response.status === 200) {
          if (isMounted) {
            setOrderCreated(true);
          }
        } else {
          throw new Error("Ошибка при создании заказа");
        }
      } catch (error) {
        if (isMounted) {
          setErrorMessage(error.message);
        }
      } finally {
        setTimeout(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        }, 2000);
      }
    };

    createOrder();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className='complete'>
      {isLoading && (
        <div className="spinner">
          <SyncLoader color='black' loading={isLoading} size={20} />
        </div>
      )}
      {!isLoading && orderCreated && (
        <div className='complete'>
          <IconSuccses />
          <h1>Замовлення успішне!</h1>
          <button className='complete-btn' onClick={onClose}>ПРОДОВЖИТИ ПОКУПКИ</button>
        </div>
      )}
      {!isLoading && errorMessage && (
        <div className='complete'>
          <IconError />
          <h1>Ой...Щось пішло не так:(</h1>
          <button className='complete-btn' onClick={onClose}>ПРОДОВЖИТИ ПОКУПКИ</button>
          <button className='complete-btn' onClick={() => setActiveTab(1)}>НАЗАД</button>
        </div>
      )}
    </div>
  );
}

export default Complete;
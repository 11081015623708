import axios from 'axios';
import { variables } from './variables.js';

const API_URL = variables.AUTH_API;

export const validateToken = async (token, setIsAuthenticated, setLoginError) => {
  try {
    const response = await axios.get(`${API_URL}/validate`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      setIsAuthenticated(true);
      setLoginError('');
    } else {
      setLoginError('Токен недійсний або закінчився. Будь ласка, увійдіть знову.');
      localStorage.removeItem('token');
    }
  } catch (error) {
    setLoginError('Помилка під час валідації токена.');
  }
};

export const handleLoginSubmit = async (username, password, setIsAuthenticated, setLoginError) => {
  try {
    const response = await axios.post(`${API_URL}/login`, {
      username,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;

    if (data.success) {
      localStorage.setItem('token', data.token);
      setIsAuthenticated(true);
      setLoginError('');
    } else {
      setLoginError('Помилка входу: ' + data.message);
    }
  } catch (error) {
    setLoginError('Помилка під час спроби входу.');
  }
};

export default {
  validateToken,
  handleLoginSubmit,
};

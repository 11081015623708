import React, { useState, useEffect } from 'react';
import ProductCard from './ProductItem';
import { useProduct } from '../../utils/hooks/useProduct';
import { useSelector } from 'react-redux';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProductList({ categoryId, searchInput }) {
  // const { toggle, isToggled } = useToggle();
  const { products, fetchProducts } = useProduct();
  const { minPrice: filterMinPrice, maxPrice: filterMaxPrice } = useSelector((state) => state.product.filter);

  const [minPrice, setMinPrice] = useState(filterMinPrice || '');
  const [maxPrice, setMaxPrice] = useState(filterMaxPrice || '');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    fetchProducts();
  }, []);

  const filteredProducts = products
    .filter((product) => {
      const stockFilter = product.inStock === true;
      const minPriceFilter = minPrice === '' || product.defaultPrice >= parseFloat(minPrice);
      const maxPriceFilter = maxPrice === '' || product.defaultPrice <= parseFloat(maxPrice);
      const categoryFilter = !categoryId || product.categoryID === categoryId;  // Если categoryId пустой, не фильтровать по категории
      const searchFilter = !searchInput || product.name.toLowerCase().includes(searchInput) || product.description.toLowerCase().includes(searchInput) || product.brand.toLowerCase().includes(searchInput);
      return categoryFilter && minPriceFilter && maxPriceFilter && searchFilter && stockFilter;
    })
    .sort((a, b) => {
      if (sortOrder === 'lowToHigh') {
        return a.defaultPrice - b.defaultPrice;
      } else if (sortOrder === 'highToLow') {
        return b.defaultPrice - a.defaultPrice;
      } else {
        return 0;
      }
    });

  const [visibleItems, setVisibleItems] = useState(4);
  const [isToggled, setIsToggled] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const togglePanel = () => {
    if (isToggled) {
      // Если панель открыта, запускаем анимацию закрытия
      setIsClosing(true);

      // Ждем окончания анимации (500 мс), затем скрываем элемент
      setTimeout(() => {
        setIsToggled(false); // Скрываем элемент после анимации
        setIsClosing(false); // Сбрасываем состояние
      }, 500); // Время должно совпадать с transition в CSS
    } else {
      setIsToggled(true); // Открываем панель сразу
    }
  };

  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 4);
  };

  return (
    <div className='shop'>
      <div className='filter-control'>
        <div className='filter-div toggle'>
          <a onClick={togglePanel}><FontAwesomeIcon icon={icons.filter} /></a>
        </div>
        <div className={`filter-option ${isToggled ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
          <div className="filter-div filter-spec">
            <label htmlFor="size">Ціна:</label>
            <input type="number" id="minPrice" placeholder="мін." name="minPrice" value={minPrice} onChange={(event) => setMinPrice(event.target.value)} />
            <p>-</p>
            <input type="number" id="maxPrice" placeholder="макс." name="maxPrice" value={maxPrice} onChange={(event) => setMaxPrice(event.target.value)} />
          </div>
          <div className="filter-div">
            <label>Сортувати:</label>
            <select id="sort" name="sort" value={sortOrder} onChange={(event) => setSortOrder(event.target.value)}>
              <option value="">За замовчуванням</option>
              <option value="lowToHigh">Дешеві вищі</option>
              <option value="highToLow">Дорогі вище</option>
            </select>
          </div>
        </div>
      </div>
      <div className="product-grid">
        {filteredProducts
          .slice(0, visibleItems)
          .map((product, index) =>
            <ProductCard product={product} key={index} />)}
      </div>
      {visibleItems < filteredProducts.length && (
        <button className="load-more button" onClick={loadMoreItems}>Завантажити ще</button>
      )}
    </div>
  );
}

export default ProductList;

import React, { useEffect, useState, useRef } from 'react';
import ProductSizes from './SizesTable';
import { useProduct } from '../../utils/hooks/useProduct';
import { useStock } from '../../utils/hooks/useUtil';
import axios from 'axios';
import { variables } from '../../utils/api/variables.js';
import toast, { Toaster } from 'react-hot-toast';
import { getCategories } from '../../utils/api/categoryApi.js';

function Products() {
  const { products, fetchProducts, updateExistingProduct, removeProduct } = useProduct();
  const [localProduct, setLocalProduct] = useState({});
  const [images, setImages] = useState([]);
  const [productPictures, setPictures] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [categories, setCategories] = useState([]);
  const getStock = useStock();
  const API_URL = variables.PRODUCT_API;
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchProducts();
    if (localProduct) {
      setImages(localProduct.pictures);
    }

    const mainImageFromDb = images?.find(image => image.isMain === true);
    if (mainImageFromDb) {
      setMainImage(mainImageFromDb);
    } else if (localProduct?.pictures && localProduct?.pictures?.length > 0) {
      setMainImage(localProduct.pictures); // Default to the first image
    }

    const loadCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    };

    loadCategories();
  }, [fetchProducts, localProduct]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalProduct(prevState => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const previewUrls = files.map((file) => ({
      pictureUrl: URL.createObjectURL(file),
      name: file.name
    }));

    setPictures(files);
    setImagePreviews(previewUrls);

    if (!mainImage && files.length) {
      setMainImage(previewUrls[0]);
    }
  };

  useEffect(() => {
    console.log('Updated images:', productPictures);
  }, [productPictures]);

  const handleMainImageChange = (fileName) => {
    setMainImage(fileName);
  };

  const handleUploadImages = async () => {
    const formData = new FormData();

    productPictures.forEach((image) => {
      formData.append('pictures', image);
    });

    try {
      const response = await axios.post(`${API_URL}/uploadPhotos`, formData);
      console.log('Files uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleCreateProduct = async () => {
    if (!productPictures || productPictures.length === 0) {
      toast.error("Завантажте хоча б одне зображення", {
        duration: 2000,
        position: 'top-right',
      });
      return;
    }

    const createProductPromise = new Promise(async (resolve, reject) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const pictures = productPictures.map((file, index) => ({
          PictureUrl: `https://apitest.useek.com.ua/spa/${file.name}`,
          ProductID: localProduct?.productID || '',
          IsMain: mainImage ? mainImage.name === file.name : index === 0
        }));

        console.log(pictures);
        localProduct.Pictures = pictures;

        await handleUploadImages();
        const response = await axios.post(`${API_URL}`, localProduct);

        if (response.status === 200) {
          resolve(response);
        } else {
          reject(new Error("Щось пішло не так :("));
        }
      } catch (error) {
        reject(error);
      }
    });

    toast.promise(
      createProductPromise,
      {
        loading: 'Створюємо продукт...',
        success: `${localProduct.name} успішно створено!`,
        error: 'Помилка при створенні продукту',
      },
      {
        duration: 2000,
        position: 'top-center',
      }
    );
  };

  return (
    <div className="admin-product">
      <table className='product-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Назва</th>
            <th>Наявність</th>
          </tr>
        </thead>
        <tbody className='tbody-rows'>
          {products.map((product, index) => (
            <tr key={index} onClick={() => setLocalProduct(product)}>
              <td>{product.productID}</td>
              <td>{product.brand} {product.name}</td>
              <td>{getStock(product.inStock)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='product-panel'>
        <div className='product-info-top'>
          <h2>{localProduct?.brand} {localProduct?.name}</h2>
          <div className='product-panel-img'>
            <img src={mainImage.pictureUrl} alt="Main Product" />
          </div>

          <div className="product-panel-info">
            <label>
              ID
              <input value={localProduct?.productID} readOnly />
            </label>
            <label>
              Бренд
              <input id="productBrand" name="brand" maxLength="50" value={localProduct?.brand || ''} onChange={handleInputChange} />
            </label>
            <label>
              Назва
              <input id="productName" name="name" maxLength="100" value={localProduct?.name || ''} onChange={handleInputChange} />
            </label>
            <label>
              Опис
              <textarea id="productDescription" name="description" maxLength="500" rows="4" value={localProduct?.description || ''} onChange={handleInputChange}></textarea>
            </label>
            <label>
              Категорія
              <select
                name="categoryID"
                value={localProduct?.categoryID || ''}
                onChange={handleInputChange}
              >
                <option value="">Виберіть категорію</option>
                {categories.map((category) => (
                  <option key={category.categoryID} value={category.categoryID}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <ProductSizes product={localProduct} key={localProduct?.productID} />

          <div className='photo-selector'>
            <h2>Додати фото</h2>
            <input type="file" multiple accept="image/*" onChange={handleImageChange} ref={fileInputRef} />
            <div className="image-preview">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="image-thumbnail" onClick={() => handleMainImageChange(preview)}>
                  <img
                    src={preview.pictureUrl}
                    alt={`product-${index}`}
                    className={mainImage === preview ? 'main-image' : ''}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className='divider'>
            <button className='second-button' onClick={() => {
              setLocalProduct(null);
              setImages([]);
              setImagePreviews([]);
              setMainImage('');
              fileInputRef.current.value = null;
            }}>
              ОЧИСТИТИ
            </button>
            {localProduct && (
              <button className='second-button' onClick={() => removeProduct(localProduct?.productID)}>
                ВИДАЛИТИ
              </button>
            )}
            {localProduct?.productID && (
              <button onClick={() => updateExistingProduct({ productId: localProduct?.productID, product: localProduct })}>
                ЗБЕРЕГТИ ЗМІНИ
              </button>
            )}
            {!localProduct?.productID && (
              <button onClick={() => handleCreateProduct()}>
                ДОДАТИ ПРОДУКТ
              </button>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Products;

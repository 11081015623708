import React, { useState, useEffect } from 'react';
import './InfoContent.css';

const DeliveryAndPayment = () => {
    const [isPickupOpen, setIsPickupOpen] = useState(false);
    const [isNovaPoshtaOpen, setIsNovaPoshtaOpen] = useState(false);
    const [isNovaPoshtaTerminalOpen, setIsNovaPoshtaTerminalOpen] = useState(false);
    const [isCardPaymentOpen, setIsCardPaymentOpen] = useState(false);
    const [isCashOnDeliveryOpen, setIsCashOnDeliveryOpen] = useState(false);

    const togglePickup = () => setIsPickupOpen(!isPickupOpen);
    const toggleNovaPoshta = () => setIsNovaPoshtaOpen(!isNovaPoshtaOpen);
    const toggleNovaPoshtaTerminal = () => setIsNovaPoshtaTerminalOpen(!isNovaPoshtaTerminalOpen);
    const toggleCardPayment = () => setIsCardPaymentOpen(!isCardPaymentOpen);
    const toggleCashOnDelivery = () => setIsCashOnDeliveryOpen(!isCashOnDeliveryOpen);

    const [pickUp, setPickUp] = useState('');
    const [depNP, setDepNP] = useState('');
    const [postomatNP, setPostomatNP] = useState('');
    const [payCard, setPayCard] = useState('');
    const [payCash, setPayCash] = useState('');

    useEffect(() => {
        fetch('/delivery_payment/PickUp.html')
            .then((response) => response.text())
            .then((data) => setPickUp(data));

        fetch('/delivery_payment/DepNP.html')
            .then((response) => response.text())
            .then((data) => setDepNP(data));

        fetch('/delivery_payment/PostomatNP.html')
            .then((response) => response.text())
            .then((data) => setPostomatNP(data));

        fetch('/delivery_payment/PayCard.html')
            .then((response) => response.text())
            .then((data) => setPayCard(data));

        fetch('/delivery_payment/PayCash.html')
            .then((response) => response.text())
            .then((data) => setPayCash(data));
    }, []);

    return (
        <div>
            <div>
                <h1>Оплата і доставка</h1>
            </div>

            <div className='info-content'>
                <h3>Спосіб доставки</h3>
                <div className="delivery-item">
                    <div onClick={togglePickup} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                        <span>Самовивіз</span>
                        <span>{isPickupOpen ? '▼' : '►'}</span>
                    </div>
                    {isPickupOpen && (
                        <div className="delivery-info" dangerouslySetInnerHTML={{ __html: pickUp }} />
                    )}
                </div>

                <div className="delivery-item">
                    <div onClick={toggleNovaPoshta} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                        <span>Відділення Нова Пошта</span>
                        <span>{isNovaPoshtaOpen ? '▼' : '►'}</span>
                    </div>
                    {isNovaPoshtaOpen && (
                        <div className="delivery-info" dangerouslySetInnerHTML={{ __html: depNP }} />
                    )}
                </div>

                <div className="delivery-item">
                    <div onClick={toggleNovaPoshtaTerminal} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                        <span>Поштомат Нова Пошта</span>
                        <span>{isNovaPoshtaTerminalOpen ? '▼' : '►'}</span>
                    </div>
                    {isNovaPoshtaTerminalOpen && (
                        <div className="delivery-info" dangerouslySetInnerHTML={{ __html: postomatNP }} />
                    )}
                </div>

                <h3>Спосіб оплати</h3>
                <div className="payment-item">
                    <div onClick={toggleCardPayment} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                        <span>Оплата картою</span>
                        <span>{isCardPaymentOpen ? '▼' : '►'}</span>
                    </div>
                    {isCardPaymentOpen && (
                        <div className="payment-info" dangerouslySetInnerHTML={{ __html: payCard }} />
                    )}
                </div>

                <div className="payment-item">
                    <div onClick={toggleCashOnDelivery} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                        <span>Оплата готівкою при отриманні</span>
                        <span>{isCashOnDeliveryOpen ? '▼' : '►'}</span>
                    </div>
                    {isCashOnDeliveryOpen && (
                        <div className="payment-info" dangerouslySetInnerHTML={{ __html: payCash }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeliveryAndPayment;
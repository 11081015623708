import React from 'react';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import vodafoneIcon from "../../assets/icons/vodafone.svg";
// import kyivstarIcon from "../../assets/icons/kyivstar.svg";
// import lifecellIcon from "../../assets/icons/lifecell.svg";

const contacts = [
  {
    href: "tel:+380954505545",
    imgSrc: vodafoneIcon,
    alt: "Vodafone",
    text: "+38(095)450-55-45",
  },
  // {
  //   href: "tel:+380671234567",
  //   imgSrc: kyivstarIcon,
  //   alt: "Kyivstar",
  //   text: "+38(067)123-45-67",
  // },
  // {
  //   href: "tel:+380931234567",
  //   imgSrc: lifecellIcon,
  //   alt: "Lifecell",
  //   text: "+38(093)123-45-67",
  // },
  {
    href: "https://t.me/@minimax_biz",
    icon: icons.telegram,
    text: "@minimax_biz",
  },
  {
    href: "https://www.instagram.com/minimax.biz?igsh=YjM1NGpnMWd2dGZ0",
    icon: icons.instagram,
    text: "@minimax.biz",
  },
  {
    href: "viber://chat?number=%2B380954505545",
    icon: icons.viber,
    text: "+38(095)450-55-45",
  },
  {
    href: "mailto:minimax.biz.ua@gmail.com",
    icon: icons.email,
    text: "minimax.biz.ua@gmail.com",
  },
];

const ContactList = () => {
  return (
    <>{contacts.map((contact, index) => (
      <div className='contact-item' key={index} onClick={(e) => e.stopPropagation()}>
        <div className='social-contact-icon'>
          <a href={contact.href}>
            {contact.imgSrc ? (
              <img src={contact.imgSrc} alt={contact.alt} />
            ) : (
              <FontAwesomeIcon icon={contact.icon} />
            )}
          </a>
        </div>
        <a href={contact.href} className="contact-text">{contact.text}</a>
      </div>
    ))}
    </>
  );
};

export default ContactList;

import React, { useState, useEffect } from 'react';
import Orders from '../components/admin/OrdersTable';
import Products from '../components/admin/ProductsTable';
import TextEdit from '../components/admin/TextEdit';
import { validateToken, handleLoginSubmit } from '../utils/api/adminApi';
import SyncLoader from 'react-spinners/SyncLoader';

const tabs = ['Продукти', 'Замовлення', 'Редагувати'];

function AdminPanel() {
  const [activeTab, setActiveTab] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setLoginError('Необхідно увійти для доступу до адмін-панелі.');
      setIsLoading(false);
    } else {
      validateToken(token, setIsAuthenticated, setLoginError).finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    await handleLoginSubmit(username, password, setIsAuthenticated, setLoginError);
  };

  return (
    <div className="admin container">
      {isLoading ? (
        <div className='loader'>
          <SyncLoader color="black" loading={isLoading} size={20} />
        </div>
      ) : !isAuthenticated ? (
        <div>
          <h2>Авторизація</h2>
          <p>{loginError}</p>
          <form onSubmit={handleLogin}>
            <input type="text" name="username" placeholder="Логін" required />
            <input type="password" name="password" placeholder="Пароль" required />
            <button type="submit">Увійти</button>
          </form>
        </div>
      ) : (
        <div>
          <ul>
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={index === activeTab ? 'active' : ''}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </li>
            ))}
          </ul>
          {activeTab === 0 && <Products />}
          {activeTab === 1 && <Orders />}
          {activeTab === 2 && <TextEdit />}
        </div>
      )}
    </div>
  );
}

export default AdminPanel;

import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    SourceEditing,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { variables } from '../../utils/api/variables.js';

const TextEdit = () => {
    const [selectedPage, setSelectedPage] = useState(null);
    const [pageContent, setPageContent] = useState('');
    const API_URL = variables.TEXT_API;

    useEffect(() => {
        if (selectedPage) {
            const fetchContent = async () => {
                try {
                    const response = await fetch(selectedPage.url);
                    const data = await response.text();
                    setPageContent(data);
                } catch (error) {
                    console.error(`Error fetching ${selectedPage.url}:`, error);
                }
            };

            fetchContent();
        }
    }, [selectedPage]);

    const handleSave = async () => {
        if (!selectedPage) return;

        try {
            const response = await fetch(`${API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ pageLink: selectedPage.key, content: pageContent }),
            });

            if (!response.ok) throw new Error('Помилка при збереженні');

            alert(`Контент сторінки "${selectedPage.label}" успішно змінено!`);
        } catch (error) {
            console.error('Помилка:', error);
            alert(`Не вдалося зберегти контент сторінки "${selectedPage.label}".`);
        }
    };

    const pages = [
        { key: 'AboutUs', label: 'Про нас', url: '/AboutUs.html' },
        { key: 'ReturnsAndExchanges', label: 'Обмін/Повернення', url: '/ReturnsAndExchanges.html' },
        { key: 'delivery_payment/PickUp', label: 'Самовивіз', url: '/delivery_payment/PickUp.html' },
        { key: 'delivery_payment/DepNP', label: 'Відділення НП', url: '/delivery_payment/DepNP.html' },
        { key: 'delivery_payment/PostomatNP', label: 'Поштомат НП', url: '/delivery_payment/PostomatNP.html' },
        { key: 'delivery_payment/PayCard', label: 'Оплата карткою', url: '/delivery_payment/PayCard.html' },
        { key: 'delivery_payment/PayCash', label: 'Оплата готівкою', url: '/delivery_payment/PayCash.html' }
    ];

    const commonConfig = {
        toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'link', 'indent', 'outdent', 'sourceEditing'],
        plugins: [Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, Paragraph, Undo, SourceEditing],
        indentBlock: { offset: 1, unit: 'rem' }
    };

    return (
        <div>
            <h2>Редагування сторінок</h2>
            <ul>
                {pages.map(page => (
                    <li key={page.key} className={selectedPage?.key === page.key ? 'active' : ''}>
                        <button onClick={() => setSelectedPage(page)}>{page.label}</button>
                    </li>
                ))}
            </ul>

            {selectedPage && (
                <div>
                    <h3>Редагування: {selectedPage.label}</h3>
                    <CKEditor
                        editor={ClassicEditor}
                        data={pageContent}
                        onChange={(event, editor) => setPageContent(editor.getData())}
                        config={commonConfig}
                    />
                    <button onClick={handleSave}>Зберегти "{selectedPage.label}"</button>
                </div>
            )}
        </div>
    );
};

export default TextEdit;

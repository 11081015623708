import React, { useEffect } from 'react'
import { useCart } from "../../utils/hooks/useCart"

function OrderSummary({ updateTotalPrice }) {
  const buttonStyles = {
    layout: 'vertical',
    color: 'blue',
    label: 'checkout',
  };
  const { subtotal, delivery, discount, defaultTotal, clearCart } = useCart();

  useEffect(() => {
    updateTotalPrice(defaultTotal);
  }, []);

  const onApprove = async (data, actions) => {
    const order = await actions.order.capture();
    console.log('Order details:', order);
    const email = order.payer.email_address;
    const transactionId = order.purchase_units[0].payments.captures[0].id;
    clearCart();
    alert(`An order confirmation will be sent to email: ${email}. Transaction ID: ${transactionId}.`);
    // onPaymentComplete();
  };

  return (
    <div className='order-summary'>
      {/* <div className="space-between">
            <p>Сума</p>
            <p>{subtotal}</p>
        </div> */}
      {/* {discount > 0 && (
              <div className="space-between">
                <p>Discount</p>
                <p>-10%</p>
              </div>
        )} */}
      {/* <div className="space-between">
            <p>Доставка</p>
            <p>За цінами перевезника</p>
        </div> */}
      {/* <div className="line"></div> */}
      <div className="space-between bold">
        <p>Всього</p>
        <p>{subtotal}</p>
      </div>
    </div>
  )
}

export default OrderSummary
import React from 'react';
import background from "../assets/img/background.jpg";
import { ReactComponent as Logo } from "../assets/img/logoMinimax.svg";
import Swiper from '../components/home/Swiper';
import ProductList from '../components/product/ProductList';

function Home({ isSearchPage, searchInput }) {
  return (
    <>
      <div className={`search container ${isSearchPage ? 'active' : ''}`}>
        <div className='search-resaults'>
          <h2>Результати пошуку</h2>
          <ProductList searchInput={searchInput} />
        </div>
      </div>

      <div className={`main-view ${isSearchPage ? '' : 'active'}`}>
        <div className='hero'>
          <img
            src={background}
          />
          <div className='hero-circle'>
            <Logo />
          </div>
        </div>
        <div className='main container'>
          <Swiper />
        </div>
      </div>
    </>
  );
}

export default Home;

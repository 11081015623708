import React from 'react';
import ReactDOM from 'react-dom';
import { Transition } from "react-transition-group";
import { ReactComponent as IconClose } from './icons8-close.svg';
import './Modal.css';

export const Modal = ({ isOpen, onClose, children }) => {
    const onWrapperClick = (event) => {
        if (event.target.classList.contains("modal-wrapper")) onClose();
    };

    return ReactDOM.createPortal(
        <>
            <Transition in={isOpen} timeout={350} unmountOnExit={true}>
                {(state) => (
                    <div className={`modal modal--${state}`}>
                        <div className="modal-wrapper" onClick={onWrapperClick}>
                            <div className="modal-content">
                                <button className="modal-close-button" onClick={() => onClose()}>
                                    <IconClose />
                                </button>
                                {children}
                            </div>
                        </div>
                    </div>
                )}
            </Transition>
        </>,
        document.body
    );
};

export default Modal;
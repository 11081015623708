import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useCart } from "../utils/hooks/useCart";
import toast, { Toaster } from 'react-hot-toast';
import { useProduct } from '../utils/hooks/useProduct';
import { formatPrice } from '../utils/hooks/useUtil';
import ImageModal from '../components/modal/imageModal';
import { ReactComponent as IconNext } from '../components/home/arrow-ios-forward-svgrepo-com.svg';
import { ReactComponent as IconPrev } from '../components/home/arrow-ios-back-svgrepo-com.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

Modal.setAppElement('#root');

function ProductDetail({ param }) {
  const { addToCart } = useCart();
  const { products, fetchProducts } = useProduct();
  const [selectedSize, setSelectedSize] = useState(null);
  const [defaultSize, setDefaultSize] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const product = products.find((product) => product?.productID === String(param));

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (product) {
      if (!selectedSize) {
        const defaultSizeObj = product?.sizes.find((size) => size.price === product.defaultPrice);
        setSelectedSize(defaultSizeObj);
        setDefaultSize(defaultSizeObj);
      }
    }
  }, [selectedSize, product]);

  const addToCartHandler = () => {
    addToCart({
      product: product,
      size: selectedSize?.size || product.sizes[0].size,
      price: selectedSize?.price || product.defaultPrice,
      sizeId: selectedSize?.productSizeID
    });

    toast.success(`${product.name} доданий у корзину!`, {
      duration: 2000,
      position: 'top-left',
    });
  };

  return (
    <>
      {product && (
        <div className='flex container'>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            navigation={product.pictures.length > 1 ? {
              prevEl: '.custom-prev',
              nextEl: '.custom-next',
            } : false}
            onSwiper={(swiper) => {
              if (product.pictures.length > 1) {
                swiper.navigation.update();
              }
            }}
          >
            {product.pictures.map((picture, index) => (
              <SwiperSlide key={index}>
                <div className='product-detail-img flex-2' onClick={() => openModal(picture.pictureUrl)}>
                  <img src={picture.pictureUrl} alt={`Product Image ${index + 1}`} />
                </div>
              </SwiperSlide>
            ))}
            {product.pictures.length > 1 && (
              <div className="custom-navigation">
                <button className="swiper-button-prev custom-prev">
                  <IconPrev />
                </button>
                <button className="swiper-button-next custom-next">
                  <IconNext />
                </button>
              </div>
            )}
          </Swiper>

          <div className='product-detail-about flex-1'>
            <h2>{product.brand}</h2>
            <h1>{product.name}</h1>
            <p>
              {selectedSize ? `${formatPrice(selectedSize?.price)}` : `${formatPrice(product.defaultPrice)}`}
            </p>
            {selectedSize?.optFive && (
              <p>Від 5 товарів: {formatPrice(selectedSize.optFive)}</p>
            )}
            {selectedSize?.optTen && (
              <p>Від 10 товарів: {formatPrice(selectedSize.optTen)}</p>
            )}
            <div className='size-selector'>
              <label htmlFor="size-select">Доступні розміри:</label>
              <select
                param="size-select"
                value={selectedSize ? JSON.stringify(selectedSize) : JSON.stringify(defaultSize)}
                onChange={(e) => {
                  const selected = JSON.parse(e.target.value);
                  setSelectedSize(selected);
                }}>
                {product?.sizes?.slice()?.sort((a, b) => a.size - b.size)?.map((size, index) => (
                  <option key={index} value={JSON.stringify(size)}>
                    {size?.size} - {formatPrice(size?.price)} {size.quantity <= 3 ? `(тільки ${size.quantity} залишилося)` : ""}
                  </option>
                ))}
              </select>
            </div>
            <button onClick={() => addToCartHandler()}>В КОРЗИНУ</button>
            <p className='product-description'>{product.description}</p>
          </div>
          <Toaster />
          <ImageModal isOpen={isOpen} onClose={closeModal} imageSrc={selectedImage} />
        </div>
      )}
    </>
  );
}

export default ProductDetail;

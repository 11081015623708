export const variables = {
    // BASE_URL: "https://apitest.useek.com.ua/api/",
    // PRODUCT_API: "https://apitest.useek.com.ua/api/product",
    // ORDER_API: "https://apitest.useek.com.ua/api/order",
    // PRODUCTSIZE_API: "https://apitest.useek.com.ua/api/productsize",
    // ORDERITEM_API: "https://apitest.useek.com.ua/api/orderitem",
    // CATEGORY_API: "https://apitest.useek.com.ua/api/category",
    // AUTH_API: "https://apitest.useek.com.ua/api/auth",
    // TEXT_API: "https://apitest.useek.com.ua/api/text/update",

    BASE_URL: "https://backtest.minimax.biz/api/",
    PRODUCT_API: "https://backtest.minimax.biz/api/product",
    ORDER_API: "https://backtest.minimax.biz/api/order",
    PRODUCTSIZE_API: "https://backtest.minimax.biz/api/productsize",
    ORDERITEM_API: "https://backtest.minimax.biz/api/orderitem",
    CATEGORY_API: "https://backtest.minimax.biz/api/category",
    AUTH_API: "https://backtest.minimax.biz/api/auth",
    TEXT_API: "https://backtest.minimax.biz/api/text/update",

    // BASE_URL: "https://localhost:7089/api/",
    // PRODUCT_API: "https://localhost:7089/api/product",
    // ORDER_API: "https://localhost:7089/api/order",
    // PRODUCTSIZE_API: "https://localhost:7089/api/productsize",
    // ORDERITEM_API: "https://localhost:7089/api/orderitem",
    // CATEGORY_API: "https://localhost:7089/api/category",
    // AUTH_API: "https://localhost:7089/api/auth",
    // TEXT_API: "https://localhost:7089/api/text/update",
    //"https://localhost:7089/api/product"
}
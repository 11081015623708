import axios from 'axios';
import { variables } from './variables.js';

const API_URL = variables.PRODUCT_API

const getProducts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
}

// хз чи треба
const getProduct = async (productId) => {
  const response = await axios.get(`${API_URL}/${productId}`);
  return response.data;
}

const addProduct = async (product) => {
  const response = await axios.post(API_URL, product);
  return response;
}

const updateProduct = async (productId, product) => {
  console.log(product);
  const response = await axios.post(`${API_URL}/update/${productId}`, product);
  return response.data;
}

const deleteProduct = async (productId) => {
  const response = await axios.post(`${API_URL}/delete/${productId}`);
  return response.data;
}

export default {
  getProducts,
  getProduct,
  addProduct,
  updateProduct,
  deleteProduct
};
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getCities, getWarehouses } from '../../utils/api/novaPostaApi';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    fontFamily: 'Montserrat, sans-serif', // Установите шрифт для основного контейнера
    fontSize: '0.8rem', // Установите размер шрифта
    marginBottom: '1rem',
    border: '1px solid #dbdbdb', // изменение цвета рамки
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #0056b3', // изменение цвета рамки при наведении
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0070ba' : '#fff', // цвет выбранной опции
    color: state.isSelected ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: 'none', // цвет опции при наведении
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif', // Установите шрифт для плейсхолдера
    fontSize: '0.8rem',
    color: '#6c757d', // цвет текста плейсхолдера
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000', // цвет выбранного значения
  }),
};

const NovaPoshtaSelector = ({ updateOrderCity, updateWarehouse }) => {
  const [cities, setCities] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await getCities();
      setCities(citiesData.map((city) => ({ value: city.Ref, label: city.Description })));
    };

    fetchCities();
  }, []);

  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    updateOrderCity(selectedOption);
    const warehousesData = await getWarehouses(selectedOption.value);
    setWarehouses(warehousesData.map((warehouse) => ({ value: warehouse.Ref, label: warehouse.Description })));
    setSelectedWarehouse(null);
  };

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
    updateWarehouse(selectedOption);
  };

  return (
    <div>
      <Select
        options={cities}
        onChange={handleCityChange}
        value={selectedCity}
        placeholder="Оберіть місто"
        styles={customStyles}
      />
      {selectedCity && (
        <Select
          options={warehouses}
          onChange={handleWarehouseChange}
          value={selectedWarehouse}
          placeholder="Оберіть відділення"
          styles={customStyles}
        />
      )}
    </div>
  );
};

export default NovaPoshtaSelector;

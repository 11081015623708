import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../assets/icons/icons';
import { useCart } from '../../utils/hooks/useCart';
// import { searchProducts } from '../../store/reducers/productSlice';
import { Modal } from '../modal/Modal';
import Cart from '../../pages/Cart';
import Checkout from '../../pages/Checkout';
import ContactList from './ContactList';
import logo from '../../assets/img/nameMinimax.svg';
import AboutUs from '../../pages/AboutUs';

function Header({ setIsSearchPage, setSearchInput }) {
  const { quantity, items } = useCart();
  // const dispatch = useDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOpen, setSearchOpen] = useState(false);
  const [contactsOpen, setContactsOpen] = useState(false);
  const contactButtonRef = useRef(null);
  const messageRef = useRef(null);

  const handleOpenModal = () => {
    setModalIsOpen(true);
    setCurrentPage(1);
  };
  const handleOpenAboutUs = () => {
    setModalIsOpen(true);
    setCurrentPage(3);
  };

  const goNext = () => setCurrentPage(prev => prev + 1);
  const goBack = () => setCurrentPage(prev => prev - 1);

  const toggleSearch = () => {
    setSearchOpen(prev => !prev);
    setIsSearchPage(prev => !prev);
  };

  const toggleContacts = () => {
    setContactsOpen(prev => !prev);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalIsOpen]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  // useEffect для закриття контаків
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageRef.current && !messageRef.current.contains(event.target) && !contactButtonRef.current.contains(event.target)) {
        setContactsOpen(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 850 && contactsOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };

    if (window.innerWidth > 850 && contactsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [contactsOpen]);


  // Компонент вікна контаків
  const Message = () => (
    <div className="message-box" ref={messageRef}>
      <ContactList onClose={() => setContactsOpen(false)} />
    </div>
  );

  let content;
  switch (currentPage) {
    case 1:
      content = <Cart goNext={goNext} />;
      break;
    case 2:
      content = <Checkout goBack={goBack} onClose={() => setModalIsOpen(false)} items={items} />;
      break;
    case 3:
      content = <AboutUs onClose={() => setModalIsOpen(false)} />;
      break;
    default:
      content = <Cart goNext={goNext} />;
  }

  return (
    <nav>
      <div className="header-container">
        <div className='header-contacts-icon header-section'>
          <div className="contacts-icon" onClick={toggleContacts}>
            <FontAwesomeIcon icon={icons.addressCard} />
          </div>
        </div>
        <Link className="header-main header-section" to="/">
          <h1 onClick={() => {
            setSearchOpen(false);
            setIsSearchPage(false);
          }}>
            <img src={logo} alt="Store Logo" />
          </h1>
        </Link>
        <ul className='header-section'>
          <li ref={contactButtonRef} onClick={toggleContacts} style={{ cursor: 'pointer', position: 'relative' }}>
            ЗВ'ЯЗАТИСЯ З НАМИ
            {contactsOpen && <Message />}
          </li>
          <li><span onClick={handleOpenAboutUs} style={{ cursor: 'pointer' }}>ПРО НАС</span></li>
        </ul>
        <div className='header-tools header-section'>
          <div className="svg-icon" onClick={toggleSearch}>
            <FontAwesomeIcon icon={icons.search} />
          </div>
          <div className='svg-icon' onClick={handleOpenModal}>
            <FontAwesomeIcon icon={icons.cart} />
            {quantity > 0 && <span>{quantity > 9 ? "9+" : quantity}</span>}
          </div>
        </div>
      </div>
      <div className={`header-search ${searchOpen ? 'active' : ''}`}>
        <div className='input-wrapper'>
          <FontAwesomeIcon icon={icons.search} />
          <input type="text" id="search" placeholder="Пошук..." name="search" onChange={handleSearchChange} />
          <button className='search-back-btn' onClick={toggleSearch}>
            Вийти
          </button>
        </div>
      </div>
      <div className={`header-contacts-content ${contactsOpen ? 'active' : ''}`}>
        {contactsOpen && <ContactList onClose={() => setContactsOpen(false)} />}
      </div>
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        {content}
      </Modal>
    </nav>
  );
}

export default Header;

import React, { useState } from 'react'
import OrderSummary from './OrderSummary'
import CartItem from "../cart/CartItem";
import { useCart } from "../../utils/hooks/useCart"


function Confirmation({ data, updateField }) {
  // const { discount, applyDiscount } = useCart();
  // const [discountCode, setDiscountCode] = useState('');

  const updateTotalPrice = (totalPrice) => {
    updateField('totalPrice', totalPrice);
  }

  return (
    <div className='checkout-contentbox flex'>
      <div className='checkout-left'>
        <h1>ПЕРЕГЛЯНЬТЕ ВАШЕ ЗАМОВЛЕННЯ</h1>
        <div className="line-divider"></div>
        <CartItem></CartItem>
      </div>
      <div className='checkout-right'>
        <h2>АДРЕСА ДОСТАВКИ</h2>
        <div className="line-divider"></div>
        <p>{data.name} {data.surname}</p>
        <p>{data.email}</p>
        <p>{data.address}</p>
        <p>{data.city} {data.postalCode}</p>
        {/* { !discount > 0 && 
            <div className="discount-code">
              <input placeholder="Discount Code" type="text" onChange={(e) => setDiscountCode(e.target.value)} />
              <button onClick={() => applyDiscount(discountCode)}>Apply</button>
            </div>
          } */}
        <OrderSummary updateTotalPrice={updateTotalPrice} />
      </div>
    </div>
  )
}

export default Confirmation
import React, { useEffect, useState } from 'react';
// import './InfoContent.css';

const ReturnsAndExchanges = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('/ReturnsAndExchanges.html')
            .then((response) => response.text())
            .then((data) => setContent(data));
    }, []);

    return (
        <div dangerouslySetInnerHTML={{ __html: content }} />
    );
};

export default ReturnsAndExchanges;

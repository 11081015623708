import React, { useState, useEffect } from 'react';
import orderApi from '../../utils/api/orderApi';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OrderTable({ selectedOrder, updateOrderStatus }) {
  const [order, setOrder] = useState(selectedOrder || {});
  const [status, setStatus] = useState(selectedOrder?.status || 0);
  const [prevStatus, setPrevStatus] = useState(selectedOrder?.status || 0);

  useEffect(() => {
    setOrder(selectedOrder || {});
    setStatus(selectedOrder?.status || 0);
    setPrevStatus(selectedOrder?.status || 0);
  }, [selectedOrder]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOrder((prevOrder) => ({
      ...prevOrder,
      [name]: value,
    }));
  };

  const handleStatusChange = (event) => {
    const newStatus = parseInt(event.target.value);
    setPrevStatus(status);
    setStatus(newStatus);

    setOrder((prevOrder) => ({
      ...prevOrder,
      status: newStatus,
    }));
  };

  const handleSaveOrder = async () => {
    try {
      const response = await orderApi.updateOrder(order.orderID, order);
      if (response.status === 200) {
        updateOrderStatus(order.orderID, order.status);
      }
    } catch (error) {
      console.error('Ошибка при обновлении заказа:', error);
      setStatus(prevStatus);
    }
  };

  return (
    <>
      <div className="divider">
        <label>
          Імʼя
          <input name="userName" value={order.userName || ''} onChange={handleInputChange} />
        </label>
        <label>
          Прізвище
          <input name="userSurname" value={order.userSurname || ''} onChange={handleInputChange} />
        </label>
      </div>
      <label>
        Email
        <input name="userEmail" value={order.userEmail || ''} onChange={handleInputChange} />
      </label>
      <label>
        Телефон
        <input name="userPhone" value={order.userPhone || ''} onChange={handleInputChange} />
      </label>
      <div className="divider">
        <label>
          Спосіб отримання
          <input name="deliveryType" value={order.deliveryType || ''} onChange={handleInputChange} />
        </label>
      </div>
      <label>
        Адреса
        <input name="address" value={order.address || ''} onChange={handleInputChange} />
      </label>
      {order.npCity && (
        <label>
          Нова Пошта місто
          <input name="npCity" value={order.npCity || ''} onChange={handleInputChange} />
        </label>
      )}
      <div className="divider">
        <label>
          Оновити статус
          <select name="status" value={status} onChange={handleStatusChange}>
            <option value={0} disabled={status === 0}>В обробці</option>
            <option value={1} disabled={status === 1}>У виконанні</option>
            <option value={2} disabled={status === 2}>Відправлено</option>
            <option value={3} disabled={status === 3}>Доставлено</option>
            <option value={4} disabled={status === 4}>Скасовано</option>
          </select>
        </label>
        <label>
          Ціна всього
          <input value={order.totalPrice || ''} readOnly />
        </label>
      </div>
      <button onClick={handleSaveOrder}>
        <FontAwesomeIcon icon={icons.save} />
      </button>
    </>
  );
}

export default OrderTable;

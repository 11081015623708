import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteSize, addSize, updateSize } from '../../store/actions/sizeActions';

export const useSize = () => {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const addSizeHandler = ({ size, price, quantity, productId }, e) => {
    return dispatch(addSize({ size, price, quantity, productId }));
  };

  const updateSizeHandler = ({ sizeId, size }) => {
    return dispatch(updateSize({ sizeId, size }));
  };

  const deleteSizeHandler = (sizeId) => {
    return dispatch(deleteSize(sizeId));
  };

  return {
    addSize: addSizeHandler,
    updateSize: updateSizeHandler,
    deleteSize: deleteSizeHandler,
  };
};

import axios from 'axios';
import { variables } from './variables.js';

const API_URL = variables.CATEGORY_API

export const getCategories = async () => {
  const response = await axios.get(API_URL);
  return response.data;
}

export default {
  getCategories
};
import React, { useState } from 'react';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSize } from '../../utils/hooks/useSize';
import toast, { Toaster } from 'react-hot-toast';
import sizeApi from '../../utils/api/sizeApi';

function ProductSizes({ product }) {
  const { addSize, updateSize, deleteSize } = useSize();
  const [newSize, setNewSize] = useState({ size: '', price: '', quantity: '' });
  const [editedSizes, setEditedSizes] = useState(
    product.sizes ? product.sizes.map((size) => ({ ...size })) : []
  );

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setEditedSizes((prevSizes) =>
      prevSizes.map((size, i) =>
        i === index ? { ...size, [name]: value } : size
      )
    );
  };

  const handleAddSize = async () => {
    if (!newSize.size || !newSize.price || !newSize.quantity) {
      return toast.error('Заповніть усі поля!');
    }

    try {
      await addSize({ ...newSize, productId: product.productID });
      const response = await sizeApi.getProductSizesByProductId(product.productID);
      setEditedSizes(response || []);
      setNewSize({ size: '', price: '', quantity: '' });
      toast.success('Розмір успішно додано!');
    } catch (error) {
      toast.error('Помилка при додаванні розміру');
    }
  };

  const handleDeleteSize = async (sizeId) => {
    if (!window.confirm('Ви впевнені, що хочете видалити цей розмір?')) {
      return;
    }

    try {
      await deleteSize(sizeId);
      const response = await sizeApi.getProductSizesByProductId(product.productID);
      setEditedSizes(response || []);
      toast.success('Розмір успішно видалено!');
    } catch (error) {
      toast.error('Помилка при видаленні розміру');
    }
  };

  const handleUpdateSize = async (sizeId, updatedSize) => {
    try {
      await updateSize({ sizeId, size: updatedSize });
      const response = await sizeApi.getProductSizesByProductId(product.productID);
      setEditedSizes(response || []);
      toast.success('Розмір успішно оновлено!');
    } catch (error) {
      toast.error('Помилка при оновленні розміру');
    }
  };

  return (
    <>
      <table className='size-table'>
        <thead>
          <tr>
            <th>Опція</th>
            <th>Ціна</th>
            <th>Кількість</th>
            <th>Змінити</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                id="size"
                type="text"
                value={newSize.size}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    size: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <input
                id="price"
                type="number"
                value={newSize.price}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    price: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <input
                id="quantity"
                type="number"
                value={newSize.quantity}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    quantity: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <button onClick={handleAddSize}>ДОДАТИ</button>
            </td>
          </tr>
          {editedSizes.map((size, index) => (
            <tr key={index}>
              <td>
                <input
                  name="size"
                  type="text"
                  value={size.size}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  name="price"
                  type="number"
                  value={size.price}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  name="quantity"
                  type="number"
                  value={size.quantity}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <button onClick={() => handleDeleteSize(size.productSizeID)}>
                  <FontAwesomeIcon icon={icons.trash}></FontAwesomeIcon>
                </button>
                <button
                  onClick={() => handleUpdateSize(size.productSizeID, size)}
                >
                  <FontAwesomeIcon icon={icons.save}></FontAwesomeIcon>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <Toaster />
      </table>
    </>
  );
}

export default ProductSizes;
